// httpClients.ts
import axios from 'axios'

const httpClient = axios.create({})

// Add a request interceptor
httpClient.interceptors.request.use(
  function (config: any) {
    return Promise.resolve(config)
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      console.log('401')

      window.location.href = window.location.protocol + '//' + window.location.host + '/logout'

      console.log('401-2')
    }
    if (error.response.status === 403) {
      console.log('403')
    }

    //return Promise.reject(error);
  }
)

export default httpClient
