export type HourKeyType = 'lunch' | 'dinner' | 'outside_business_hours'
export type HourType = HourKeyType | null

const statusCode = {
  success: 200,
  unauthorized: 401,
  error: 400,
  forbidden: 403
}

export const classificationType = [
  {
    type: 'SIZE',
    label: 'Tamanho'
  }
]

export const categoriesNames = {
  pizzasWithSizes: 'Pizzas com tamanhos',
  builderMassas: 'Builder Massas',
  builderPizzas: 'Builder Pizzas',
  builderIngredientes: 'Builder Ingredientes',
  sugestoes: 'Sugestões'
}

export const hourTypes: { [key in HourKeyType]: string } = {
  lunch: 'almoco',
  dinner: 'jantar',
  outside_business_hours: 'fora_de_horario'
}

export default statusCode
