import httpClient from 'src/helpers/httpClient'
import authConfig from 'src/configs/auth'
import statusCode from 'src/helpers/constants'
import handleResponseError from 'src/helpers'
import { routes } from 'src/configs/routes'
import jwt_decode from 'jwt-decode'

const requestPatchUser = async (username, name) => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)
  const decodedToken = jwt_decode(token)
  const id = decodedToken.sub
  try {
    const response =await httpClient.patch(
      `${routes.userEndpoint}${id}`,
      {
        name: name,
        username: username
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return {
      data: response.data,
      status: statusCode.success,
      msg: 'Usuário atualizado com sucesso'
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestPatchPassword = async password => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)
  const decodedToken = jwt_decode(token)
  const id = decodedToken.sub
  try {
    const response =await httpClient.patch(
      `${routes.userEndpoint}${id}/password`,
      {
        password
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    return {
      data: response.data,
      status: statusCode.success,
      msg: 'Senha atualizada com sucesso'
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestUser = async () => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName)
  const decodedToken = jwt_decode(token)
  const id = decodedToken.sub
  try {
    const response =await httpClient.get(`${routes.userEndpoint}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return {
      data: response.data,
      status: statusCode.success,
      msg: 'Dados do usuário recebidos com sucesso'
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

export { requestPatchUser, requestUser, requestPatchPassword }
