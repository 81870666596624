// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'
import jwt_decode from "jwt-decode";

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import httpClient from 'src/helpers/httpClient'

// ** Config
import authConfig from 'src/configs/auth'

// ** Types
import { AuthValuesType, RegisterParams, LoginParams, ErrCallbackType, UserDataType } from './types'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState<boolean>(defaultProvider.isInitialized)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      setIsInitialized(true)
      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)!
      if (storedToken) {

        const storedUserData = window.localStorage.getItem(authConfig.storageUserData)!


        const mockUser = {
          id: 123,
          role:  JSON.parse(storedUserData).role,
          email: JSON.parse(storedUserData).email,
          fullName: JSON.parse(storedUserData).fullname,
          username: JSON.parse(storedUserData).username,
          password: JSON.parse(storedUserData).password,
        }
        setUser(mockUser)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    initAuth()
  }, [])

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    console.log('params', params)
    console.log('authConfig.loginEndpoint', authConfig.loginEndpoint)

    httpClient.post(authConfig.loginEndpoint, params)
      .then(async res => {
        console.log('tentou login')
        window.localStorage.setItem(authConfig.storageTokenKeyName, res.data.access_token)
        const returnUrl = router.query.returnUrl
        const decoded = jwt_decode(res.data.access_token) as any
        const mockUser = {
          id: 123,
          role: decoded.role,
          email: params.username,
          fullName: params.username,
          username: params.username,
          password: params.password
        }
        setUser(mockUser)
        await window.localStorage.setItem('userData', JSON.stringify(mockUser))
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
        router.replace(redirectURL as string)
      })

      // .then(() => {
      //   axios
      //     .get(authConfig.meEndpoint, {
      //       headers: {
      //         Authorization: window.localStorage.getItem(authConfig.storageTokenKeyName)!
      //       }
      //     })
      //     .then(async response => {
      //       const returnUrl = router.query.returnUrl

      //       setUser({ ...response.data.userData })
      //       await window.localStorage.setItem('userData', JSON.stringify(response.data.userData))

      //       const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

      //       router.replace(redirectURL as string)
      //     })
      // })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    router.push('/login')
  }

  const handleRegister = (params: RegisterParams, errorCallback?: ErrCallbackType) => {
    httpClient
      .post(authConfig.registerEndpoint, params)
      .then(res => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error)
        } else {
          handleLogin({ username: params.username, password: params.password })
        }
      })
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
