import httpClient from 'src/helpers/httpClient'
import statusCode from 'src/helpers/constants'
import handleResponseError from 'src/helpers'
import { routes } from 'src/configs/routes'


const requestNotApprovedOrder = async () => {
  try {
   const response = await httpClient.get(
      `${routes.ordersEndpoint}all/NOT_APPROVED/none`,
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestOrders = async () => {
  try {
   const response = await httpClient.get(
      `${routes.ordersEndpoint}all/list/none`,
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestOrdersByCPf = async (cpf) => {
  try {
   const response = await httpClient.get(
      `${routes.ordersEndpoint}search/cpf/${cpf}`,
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestOrdersById = async (id) => {
  try {
   const response = await httpClient.get(
      `${routes.ordersEndpoint}${id}`,
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestUpdateById = async (id, body) => {
  try {
   const response = await httpClient.post(
      `${routes.ordersEndpoint}update/${id}`,
      body,
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}

const requestApprovedById = async (id, reason) => {
  try {
   const response = await httpClient.post(
      `${routes.ordersEndpoint}approve/${id}`,
      {reason},
      {
        headers: {
          Authorization: `${process.env.INTERNAL_PUBLIC_TOKEN}`
        }
      }
    )

    return {
      status: statusCode.success,
      data: response.data,
    }
  } catch (err) {
    return handleResponseError(err)
  }
}





export {
  requestApprovedById,
  requestNotApprovedOrder,
  requestOrders,
  requestOrdersByCPf,
  requestOrdersById,
  requestUpdateById
}
