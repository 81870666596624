// routes.ts
export const routes = {
  userEndpoint: process.env.API_BASE_URL + 'user/',
  menuEndpoint: process.env.API_BASE_URL + 'menu/',
  menusEndpoint: process.env.API_BASE_URL + 'menus/',
  campaignsEndpoint: process.env.API_BASE_URL + 'campaigns/',
  categoryEndpoint: process.env.API_BASE_URL + 'category/',
  categoriesEndpoint: process.env.API_BASE_URL + 'categories/',
  subcategoryEndpoint: process.env.API_BASE_URL + 'subcategory/',
  subcategoriesEndpoint: process.env.API_BASE_URL + 'subcategories/',
  storeEndpoint: process.env.API_BASE_URL + 'store/',
  storesEndpoint: process.env.API_BASE_URL + 'stores/',
  syncAllProductsEndpoint: process.env.API_BASE_URL + 'products/auto-sync/',
  syncAllStoresEndpoint: process.env.API_BASE_URL + 'stores/auto-sync/',
  syncAllMenusEndpoint: process.env.API_BASE_URL + 'menus/auto-sync/',
  productEndpoint: process.env.API_BASE_URL + 'product/',
  productsEndpoint: process.env.API_BASE_URL + 'products/',
  sizeEndpoint: process.env.API_BASE_URL + 'size/',
  sizesEndpoint: process.env.API_BASE_URL + 'sizes/',
  classificationEndpoint: process.env.API_BASE_URL + 'classification/',
  classificationsEndpoint: process.env.API_BASE_URL + 'classifications/',
  settingsEndpoint: process.env.API_BASE_URL + 'settings/',
  millesDaysInfoEndpoint: process.env.API_BASE_URL + 'milles/',
  ordersEndpoint: process.env.API_BASE_URL + 'order/',
  atenaTokenEndpoint: process.env.ATENA_BASE_URL + 'token/',
  egoiEndpoint: process.env.EGOI_URL + 'send/'
}
