/* eslint-disable react/prop-types */
// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// Import
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout'

// ** Navigation Imports
import VerticalNavItemsADMINISTRADOR from 'src/navigation/vertical'
import VerticalNavItemsGERENTE from 'src/navigation/vertical/manager'
import VerticalNavItemsGARCOM from 'src/navigation/vertical/garcom'
import HorizontalNavItems from 'src/navigation/horizontal'

import VerticalAppBarContent from './components/vertical/AppBarContent'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import { useAuth } from 'src/hooks/useAuth'

// ** User Import
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface Props {
  children: ReactNode
}

const AppBrand = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img src='/images/logos/logo-1900-transparent-white.png' alt='logo' width='30' height='14' />
      <Typography variant='h6' sx={{ ml: 2 }}>
        Backoffice App
      </Typography>
    </Box>
  )
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const { user } = useAuth()

  console.log('User role: ', user?.role)

  const getVerticalNavItems = () => {
    switch (user?.role) {
      case 'administrador':
        return VerticalNavItemsADMINISTRADOR()
      case 'gerente':
        return VerticalNavItemsGERENTE()
      case 'garcom':
        return VerticalNavItemsGARCOM()
      default:
        return []
    }
  }

  return (
    <Layout
      verticalNavItems={getVerticalNavItems()}
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      verticalNavMenuBranding={() => <AppBrand />}
      footerContent={() => ''}
      {...(settings.layout === 'horizontal'
        ? {
            // ** Navigation Items
            horizontalNavItems: HorizontalNavItems(),

            // ** AppBar Content
            horizontalAppBarContent: () => (
              <HorizontalAppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings} />
            )
          }
        : {
            // ** Navigation Items
            verticalNavItems: getVerticalNavItems(),

            // ** AppBar Content
            verticalAppBarContent: props => (
              <VerticalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            )
          })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
