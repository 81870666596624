// ** Icon imports
import {  Notebook,  } from 'mdi-material-ui'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      sectionTitle: 'Conteúdo'
    },
    {
      title: 'Cardápios',
      icon: Notebook,
      path: '/cardapios',
      action: 'manage',
      subject: 'cardapio-page',
      children: [
        {
          title: 'Lista de cardápios',
          path: '/cardapios',
          action: 'manage',
          subject: 'lista-cardapio',
        },
        {
          title: 'Categorias',
          path: '/cardapios/categorias'
        },
        {
          title: 'Subcategorias',
          path: '/cardapios/subcategorias'
        }
      ]
    },
    {
      title: 'Produtos',
      icon: Notebook,
      path: '/produtos',
      children: [
        {
          title: 'Todos',
          path: '/produtos/'
        },
        {
          title: 'Associados',
          path: '/produtos/classificacoes'
        },
        {
          title: 'Classificados',
          path: '/produtos/produtosagrupados'
        }
      ]
    },
  ]
}

export default navigation
