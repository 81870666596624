import statusCode from "./constants";

const handleResponseError = (err: any) => {
    if (err.response?.status === statusCode.unauthorized) {
        return {
            status: statusCode.unauthorized,
            msg: 'Não autorizado',
            data: null,
        };
    }
    
    return {
        status: statusCode.error,
        msg: err.response?.data?.error,
        data: [],
    };
};

export default handleResponseError;