// ** Icon imports
import { Notebook } from 'mdi-material-ui'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      sectionTitle: 'Conteúdo'
    },
    {
      title: 'Vouchers',
      icon: Notebook,
      path: '/vouchers'
    }
  ]
}

export default navigation
