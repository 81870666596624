// ** Icon imports

import { Account, MessageAlert, FileDocument, Engine, OfficeBuilding, Notebook } from 'mdi-material-ui'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      sectionTitle: 'Conteúdo'
    },

    // {
    //   title: 'Agenda',
    //   icon: CalendarBlankOutline,
    //   path: '/agenda'
    // },
    // {
    //   title: 'Banners',
    //   icon: Image,
    //   children: [
    //     {
    //       title: 'Inicial',
    //       path: '/banners/paginainicial'
    //     },
    //     {
    //       title: 'Inicial - Dicas',
    //       path: '/banners/inicialdicas'
    //     },
    //     {
    //       title: 'Campanha',
    //       path: '/banners/campanha'
    //     },
    //     {
    //       title: 'Acompanhamento',
    //       path: '/banners/acompanhamento'
    //     },
    //     {
    //       title: 'Destaques',
    //       path: '/banners/destaques'
    //     }
    //   ]
    // },
    // {
    //   title: 'Botões',
    //   icon: GestureTap,
    //   path: '/botoes'
    // },
    // {
    //   title: 'Imagens',
    //   icon: Image,
    //   children: [
    //     {
    //       title: 'Home',
    //       path: '/imagens/home'
    //     },
    //     {
    //       title: 'Pagamento',
    //       path: '/imagens/pagamento'
    //     }
    //   ]
    // },
    // {
    //   title: 'Milles',
    //   icon: Ticket,
    //   path: '/milles'
    // },
    {
      title: 'Pedidos',
      icon: FileDocument,
      path: '/pedidos'
    },
    {
      title: 'SMS',
      icon: MessageAlert,
      path: '/sms'
    },
    {
      title: 'Notificações',
      icon: MessageAlert,
      path: '/notificacoes'
    },
    {
      title: 'Milles',
      icon: Notebook,
      path: '/milles'
    },
    {
      title: 'Usuários',
      icon: Account,
      path: '/usuarios'
    },
    {
      title: 'Lojas',
      icon: OfficeBuilding,
      path: '/lojas'
    },
    {
      title: 'Cardápios',
      icon: Notebook,
      path: '/cardapios',
      action: 'all',
      subject: 'cardapio-page',
      children: [
        {
          title: 'Lista de cardápios',
          path: '/cardapios',
          action: 'all',
          subject: 'cardapios-page'
        },
        {
          title: 'Categorias',
          path: '/cardapios/categorias'
        },
        {
          title: 'Subcategorias',
          path: '/cardapios/subcategorias'
        }
      ]
    },
    {
      title: 'Produtos',
      icon: Notebook,
      path: '/produtos',
      children: [
        {
          title: 'Todos',
          path: '/produtos/'
        },
        {
          title: 'Associados',
          path: '/produtos/classificacoes'
        },
        {
          title: 'Classificados',
          path: '/produtos/produtosagrupados'
        }
      ]
    },
    {
      title: 'Configurações',
      icon: Engine,
      path: '/configuracoes'
    },
    {
      title: 'Campanhas',
      icon: Notebook,
      path: '/campanhas'
    },
    {
      title: 'Garçom',
      icon: Notebook,
      path: '/produtos',
      children: [
        {
          title: 'Vouchers',
          path: '/vouchers'
        }
      ]
    }
  ]
}

export default navigation
